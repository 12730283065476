import Api from "@/services/Index";

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/constants", config);
};

const get = (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/constants/" + id);
};

const store = async (data, config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/constants", data, config);
};

const update = async (id, formData, config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/constants/" + id, formData, config);
};
const updateByKey = async (key, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put("/constants/value/" + key, formData);
};
const delConstant = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete("/constants/" + id);
};

const getValuePrivate = async (key) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get(`/constants/value/` + key);
};

const getValue = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get(`/constants/public/value`, data);
};

const downloadFile = async (key) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/constants/download-file/' + key, {responseType: "arraybuffer"})
}

const downloadPublicFile = async (key) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/constants/public/download-file/' + key, {responseType: "arraybuffer"})
};

export default {
    getAll,
    get,
    store,
    update,
    updateByKey,
    delConstant,
    getValue,
    getValuePrivate,
    downloadFile,
    downloadPublicFile
};
