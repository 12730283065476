<template>
	<div>
		<multiselect
			v-model="selected"
			:options="options"
			:class="validateError != '' ? 'box-border-color':''"
			@input="handleInput"
			label="text"
			track-by="value"
			:multiple="false"
			:select-label="''"
			:selected-label="''"
			:deselect-label="''"
			:placeholder="$t('select')"
			:searchable="true"
            :disabled="disabled"
		>
            <span slot="noOptions">
                {{ $t('no_options') }}
            </span>
			<span slot="noResult">
                {{ $t('no_result') }}
            </span>
		</multiselect>
		<span class="invalid-feedback-custom"
		      v-show="validateError"
		      v-html="validateError">
        </span>
	</div>
</template>

<script>
	// Helpers
	import handleInput from "@/helpers/handleInput";
	import setSelected from "@/helpers/setSelected";

	// Services
	import CampusService from "@/services/CampusService";

	// Other
	import qs from "qs";

	export default {
		props: {
			value: {
				default: null
			},
			validateError: {
				type: String,
				default: ''
			},
            disabled: {
                type: Boolean,
                default: false
            },
            onlineExists: {
                type: Boolean,
                default: false
            },
            public: {
                type: Boolean,
                default: false
            },
            returnType: {
                type: String,
                default: 'id'
            }
		},
		data() {
			return {
				selected: null,
				options: [],
			}
		},
		watch: {
			value: function (newValue) {
				this.selected = this.setSelected(newValue, this.options)
			},
            onlineExists: function (){
                this.getOptions()
            }
		},
		created() {
			this.getOptions();
		},
		methods: {
			handleInput: handleInput,
			setSelected: setSelected,
			getOptions() {
				let config = {
					params: {
						sort: this.getLocaleField('name'),
						page: 1,
						limit: -1
					},
					paramsSerializer: (params) => qs.stringify(params, {encode: false})
				}

				this.options = [];
                if(this.public){
                    CampusService.getAllPublic(config)
                                 .then((response) => {
                                     const data = response.data.data;
                                     data.map(item => {
                                         this.options.push({
                                             value: item[this.returnType],
                                             text: this.getLocaleText(item, 'name')
                                         });
                                     });
                                     this.setWithExpiry(key, this.options, 0, true);
                                 })
                                 .then(() => {
                                     if(this.onlineExists){
                                         this.options = this.options.filter(o => o.value != 5)
                                     }
                                     this.selected = this.setSelected(this.value, this.options)

                                 })
                } else {
                    CampusService.getAll(config)
                                 .then((response) => {
                                     const data = response.data.data;
                                     data.map(item => {
                                         this.options.push({
                                             value: item[this.returnType],
                                             text: this.getLocaleText(item, 'name')
                                         });
                                     });
                                 })
                                 .then(() => {
                                     if(this.onlineExists){
                                         this.options = this.options.filter(o => o.value != 5)
                                     }
                                     this.selected = this.setSelected(this.value, this.options)

                                 })
                }

			}
		}
	}
</script>
