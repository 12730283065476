<template>
    <div>
        <div class="border rounded-sm pt-4 pl-4 pr-4">
            <ValidationObserver ref="updateFormValidate">
                <b-tabs content-class="py-5 position-relative" class="line-tabs" fill>
                    <div class="tabs-bottom-line"></div>
                    <b-tab :active="tabIndex == 0" @click="tabIndex = 0">
                        <template #title>
                            {{ $t('personal_info') }}
                        </template>
                        <template>
                                <h6>{{ $t('identity_information') }}</h6>
                                <div class="border p-4 rounded-sm mb-3">
                                    <b-row>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('national_id')">
                                                <ValidationProvider name="national_id" rules="required" v-slot="{ errors }">
                                                    <b-form-input v-model="formData.national_id"
                                                                  :state="errors[0] ? false : null" :disabled="true" />
                                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                </ValidationProvider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('name')">
                                                <ValidationProvider name="name" rules="required" v-slot="{ errors }">
                                                    <b-form-input v-model="formData.name"
                                                                  :state="errors[0] ? false : null"
                                                                  :disabled="true"/>
                                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                </ValidationProvider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('surname')">
                                                <ValidationProvider name="surname" rules="required" v-slot="{ errors }">
                                                    <b-form-input v-model="formData.surname"
                                                                  :state="errors[0] ? false : null"
                                                                  :disabled="true"/>
                                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                </ValidationProvider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <ValidationProvider name="birthdate" rules="required" v-slot="{ errors }">
                                                <b-form-group :label="$t('birthdate')">
                                                    <select-date v-model="formData.birthdate" :validationError="errors[0]" :disabled="true" />
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('birthplace')">
                                                <ValidationProvider name="birthplace" rules="required" v-slot="{ errors }">
                                                    <b-form-input v-model="formData.birthplace"
                                                                  :state="errors[0] ? false : null"
                                                                  :disabled="!updateMode"/>
                                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                </ValidationProvider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <ValidationProvider name="gender" rules="required" v-slot="{ errors }">
                                                <b-form-group :label="$t('gender')">
                                                    <gender-selectbox v-model="formData.gender"
                                                                      :validateError="errors[0]"
                                                                      :disabled="!updateMode"/>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <ValidationProvider name="nationality_code" rules="required" v-slot="{ errors }">
                                                <b-form-group :label="$t('nationality')">
                                                    <nationality-selectbox v-model="formData.nationality_code"
                                                                           :validateError="errors[0]"
                                                                           :disabled="!updateMode"/>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('father_name')">
                                                <ValidationProvider name="father_name" rules="required" v-slot="{ errors }">
                                                    <b-form-input v-model="formData.father_name"
                                                                  :state="errors[0] ? false : null"
                                                                  :disabled="true"/>
                                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                </ValidationProvider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('mother_name')">
                                                <ValidationProvider name="mother_name" rules="required" v-slot="{ errors }">
                                                    <b-form-input v-model="formData.mother_name"
                                                                  :state="errors[0] ? false : null"
                                                                  :disabled="!updateMode"/>
                                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                </ValidationProvider>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </div>

                                <h6>{{ $t('contact_information') }}</h6>
                                <div class="border p-4 rounded-sm mb-3">
                                    <b-row>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('email')">
                                                <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
                                                    <b-form-input v-model="formData.email"
                                                                  :state="errors[0] ? false : null"
                                                                  :disabled="!updateMode"/>
                                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                </ValidationProvider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('gsm_no')">
                                                <ValidationProvider name="mobile_tel" rules="required" v-slot="{ errors }">
                                                    <b-form-input v-model="formData.mobile_tel"
                                                                  v-mask="'### ### ## ##'"
                                                                  :state="errors[0] ? false : null"
                                                                  :disabled="!updateMode"/>
                                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                </ValidationProvider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('alternative_tel')">
                                                <ValidationProvider name="alternative_mobile_tel" rules="" v-slot="{ errors }">
                                                    <b-form-input v-model="formData.alternative_mobile_tel"
                                                                  v-mask="'### ### ## ##'"
                                                                  :state="errors[0] ? false : null"
                                                                  :disabled="!updateMode"/>
                                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                </ValidationProvider>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </div>

                                <b-row>
                                    <b-col cols="12" md="6">
                                        <h6>{{ $t('residence_address') }}</h6>
                                        <div class="border p-4 rounded-sm mb-3">
                                            <b-row>
                                                <b-col cols="12" md="6" lg="6">
                                                    <ValidationProvider name="city" rules="" v-slot="{ errors }">
                                                        <b-form-group :label="$t('city')">
                                                            <city-selectbox v-model="formData.city"
                                                                            :validateError="errors[0]"
                                                                            :disabled="!updateMode"/>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col cols="12" md="6" lg="6">
                                                    <ValidationProvider name="district" rules="" v-slot="{ errors }">
                                                        <b-form-group :label="$t('district')">
                                                            <district-outline-selectbox v-model="formData.district"
                                                                                        :city_id="formData.city"
                                                                                        :validateError="errors[0]"
                                                                                        :disabled="!updateMode"/>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col cols="12" md="12" lg="12">
                                                    <ValidationProvider name="address" rules="" v-slot="{ errors }">
                                                        <b-form-group :label="$t('address')">
                                                            <b-form-textarea v-model="formData.address" rows="3"
                                                                             :state="errors[0] ? false : null"
                                                                             :disabled="!updateMode"/>
                                                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]" />
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col cols="12" md="6" lg="6">
                                                    <b-form-group :label="$t('postal_code')">
                                                        <ValidationProvider name="postal_code" rules="" v-slot="{ errors }">
                                                            <b-form-input v-model="formData.postal_code"
                                                                          :state="errors[0] ? false : null"
                                                                          :disabled="!updateMode"/>
                                                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                        </ValidationProvider>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                        </div>
                                    </b-col>
                                    <b-col cols="12" md="6">
                                        <h6>{{ $t('communication_address') }}</h6>
                                        <div class="border p-4 rounded-sm mb-3">
                                            <b-row>
                                                <b-col cols="12" md="6" lg="6">
                                                    <ValidationProvider name="contact_city" rules="" v-slot="{ errors }">
                                                        <b-form-group :label="$t('city')">
                                                            <city-selectbox v-model="formData.contact_city"
                                                                            :validateError="errors[0]"
                                                                            :disabled="!updateMode"/>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col cols="12" md="6" lg="6">
                                                    <ValidationProvider name="contact_district" rules="" v-slot="{ errors }">
                                                        <b-form-group :label="$t('district')">
                                                            <district-outline-selectbox v-model="formData.contact_district"
                                                                                        :city_id="formData.contact_city"
                                                                                        :validateError="errors[0]"
                                                                                        :disabled="!updateMode"/>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col cols="12" md="12" lg="12">
                                                    <ValidationProvider name="contact_address" rules="" v-slot="{ errors }">
                                                        <b-form-group :label="$t('address')">
                                                            <b-form-textarea v-model="formData.contact_address" rows="3"
                                                                             :state="errors[0] ? false : null"
                                                                             :disabled="!updateMode"/>
                                                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]" />
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col cols="12" md="6" lg="6">
                                                    <b-form-group :label="$t('postal_code')">
                                                        <ValidationProvider name="contact_postal_code" rules="" v-slot="{ errors }">
                                                            <b-form-input v-model="formData.contact_postal_code"
                                                                          :state="errors[0] ? false : null"
                                                                          :disabled="!updateMode"/>
                                                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                        </ValidationProvider>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                        </div>
                                    </b-col>
                                </b-row>
                        </template>
                    </b-tab>
                    <b-tab :active="tabIndex == 1" @click="tabIndex = 1">
                        <template #title>
                            {{ $t('education_info') }}
                        </template>
                        <template>
                                <h6>{{ $t('high_school_info') }}</h6>
                                <div class="border p-4 rounded-sm mb-3">
                                    <b-row>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('graduate_high_school_name')">
                                                <ValidationProvider name="school_name" rules="" v-slot="{ errors }">
                                                    <b-form-input v-model="formData.school_name"
                                                                  :state="errors[0] ? false : null"
                                                                  :disabled="!updateMode"/>
                                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                </ValidationProvider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('high_school_diploma_score')">
                                                <ValidationProvider name="school_diploma_point" rules="" v-slot="{ errors }">
                                                    <b-form-input v-model="formData.school_diploma_point"
                                                                  type="number"
                                                                  :state="errors[0] ? false : null"
                                                                  :disabled="!updateMode"/>
                                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                </ValidationProvider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <ValidationProvider name="school_major_type" rules="" v-slot="{ errors }">
                                                <b-form-group :label="$t('high_school_major_type')">
                                                    <multi-selectbox v-model="formData.school_major_type"
                                                                     :options="schoolMajorTypeOptions"
                                                                     :multiple="false"
                                                                     :validateError="errors[0]"
                                                                     :disabled="!updateMode"/>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('graduate_high_school_code')">
                                                <ValidationProvider name="school_code" rules="" v-slot="{ errors }">
                                                    <b-form-input v-model="formData.school_code"
                                                                  :state="errors[0] ? false : null"
                                                                  :disabled="!updateMode"/>
                                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                </ValidationProvider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <ValidationProvider name="school_graduate_date" rules="" v-slot="{ errors }">
                                                <b-form-group :label="$t('high_school_graduate_date')">
                                                    <select-date v-model="formData.school_graduate_date" :validationError="errors[0]" :disabled="!updateMode" />
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('obp_point')">
                                                <ValidationProvider name="school_name" rules="" v-slot="{ errors }">
                                                    <b-form-input v-model="formData.obp_point"
                                                                  :state="errors[0] ? false : null"
                                                                  :disabled="true"/>
                                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                </ValidationProvider>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </div>
                                <h6>{{ $t('exam_info') }}</h6>
                                <div class="border p-4 rounded-sm mb-3">
                                    <b-row>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('tyt_year')">
                                                <ValidationProvider name="exam_year" rules="" v-slot="{ errors }">
                                                    <b-form-input v-model="formData.exam_year"
                                                                  type="number"
                                                                  :state="errors[0] ? false : null"
                                                                  :disabled="true"/>
                                                </ValidationProvider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('tyt_score')">
                                                <ValidationProvider name="exam_point" rules="" v-slot="{ errors }">
                                                    <b-form-input v-model="formData.tyt_point"
                                                                  type="number"
                                                                  :state="errors[0] ? false : null"
                                                                  :disabled="true"/>
                                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                    <b-form-text id="input-live-help">{{ $t('tyt_info') }}</b-form-text>
                                                </ValidationProvider>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </div>
                        </template>
                    </b-tab>
                    <b-tab :active="tabIndex == 2" @click="tabIndex = 2">
                        <template #title>
                            {{ $t('application_information') }}
                        </template>
                        <template>
                                <div class="border p-4 rounded-sm mb-3">
                                    <b-row>
                                        <b-col cols="12" md="4">
                                            <ValidationProvider name="program_code" rules="required" v-slot="{ errors }">
                                                <b-form-group :label="$t('program_select')">
                                                    <multi-selectbox v-model="formData.program_code"
                                                                        :options="programOptions"
                                                                        :multiple="false"
                                                                        :disabled="true"
                                                                        :validateError="errors[0]" />
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="school_major_type"
                                                                v-show="formData.program_code == '11022101'"
                                                                :rules="formData.program_code == '11022101' ? 'required' : ''"
                                                                v-slot="{ errors }">
                                                <b-form-group :label="$t('branch')">
                                                    <multi-selectbox v-model="formData.program_detail"
                                                                     :options="branchOptions"
                                                                     :validateError="errors[0]" />
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="interview_type" rules="required" v-slot="{ errors }">
                                                <b-form-group :label="$t('interview_preference')">
                                                    <parameter-selectbox v-model="formData.interview_type"
                                                                         code="fashion_design_application_interview_preference"
                                                                         :public="true"
                                                                         :multiple="false"
                                                                         :validateError="errors[0]" />
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col cols="12" md="8">
                                            <ValidationProvider name="letter_of_intent" rules="required" v-slot="{ errors }">
                                                <b-form-group :label="$t('letter_of_intent')">
                                                    <b-form-textarea v-model="formData.letter_of_intent" rows="7"
                                                                     placeholder="Bu alana kendinizi tanıtan, bölümü seçmedeki amaçlarınızı belirten ve şimdiye kadar yaptığınız çalışmaları içeren bir niyet mektubu yazınız."
                                                                     :state="errors[0] ? false : null"
                                                                     :disabled="!updateMode"/>
                                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]" />
                                                </b-form-group>
                                            </ValidationProvider>
                                            <b-button variant="outline-primary" @click="downloadFile" v-if="formData.document">
                                                <i class="ri-download-2-line font-size-15 mr-2"></i>{{$t('file_download')}}
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </div>
                        </template>
                    </b-tab>
                    <b-tab :active="tabIndex == 3" @click="tabIndex = 3">
                        <template #title>
                            {{ $t('special_talent_exam') }}
                        </template>
                        <template>
                            <h6>{{ $t('exam_info') }}</h6>
                            <div class="border p-4 rounded-sm mb-3" v-if="!updateMode">
                                <b-row>
                                    <b-col cols="6" md="6" class="text-right text-black-50 font-size-16">{{ $t('exam_place') }}</b-col>
                                    <b-col cols="6" md="6" class="text-size-16">{{formData.exam_location != null ? formData.exam_location : '--- ---'}}</b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="6" md="6" class="text-right text-black-50 font-size-16">{{ $t('exam_datetime') }}</b-col>
                                    <b-col cols="6" md="6" class="text-size-16">{{formData.exam_date != null ? formData.exam_date : '--.--.---- --:--'}}</b-col>
                                </b-row>
                            </div>
                            <div class="border p-4 rounded-sm mb-3" v-else>
                                <b-row class="d-flex justify-content-center">
                                    <b-col cols="12" md="6">
                                        <b-form-group :label="$t('exam_place')">
                                            <ValidationProvider name="exam_location" rules="" v-slot="{ errors }">
                                                <campus-selectbox-no-cache v-model="formData.exam_location"
                                                                           :return-type="getLocaleField('name')"
                                                              :validate-error="errors[0]"
                                                              :disabled="!updateMode"/>
                                                <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                            </ValidationProvider>
                                        </b-form-group>
                                        <b-form-group :label="$t('exam_datetime')">
                                            <b-row>
                                                <b-col cols="6">
                                                    <ValidationProvider name="exam_date" rules="" v-slot="{ errors }">
                                                        <b-form-input type="date" size="sm" v-model="formData.exam_date"></b-form-input>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col cols="6">
                                                    <ValidationProvider name="exam_date" rules="" v-slot="{ errors }">
                                                        <b-form-input type="time" size="sm" v-model="formData.exam_time"></b-form-input>
                                                    </ValidationProvider>
                                                </b-col>
                                            </b-row>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </div>
                            <h6>{{ $t('exam_result') }}</h6>
                            <div class="border p-4 rounded-sm mb-3" v-if="!updateMode">
                                <b-row>
                                    <b-col cols="6" md="6" class="text-right text-black-50 font-size-16">{{ $t('exam_score') }}</b-col>
                                    <b-col cols="6" md="6" class="text-size-16">{{formData.exam_point != null ? formData.exam_point : '---'}}</b-col>
                                </b-row>
                            </div>
                            <div class="border p-4 rounded-sm mb-3" v-else>
                                <b-row class="d-flex justify-content-center">
                                    <b-col cols="12" md="6">
                                        <b-form-group :label="$t('exam_score')">
                                            <ValidationProvider name="exam_point" rules="" v-slot="{ errors }">
                                                <b-form-input v-model="formData.exam_point"></b-form-input>
                                                <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                            </ValidationProvider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </div>
                        </template>
                    </b-tab>
                    <b-tab :active="tabIndex == 4" @click="tabIndex = 4">
                        <template #title>
                            {{ $t('application_status') }}
                        </template>
                        <template>
                            <h6>{{ $t('estimate_status') }}</h6>
                            <div class="border p-4 rounded-sm mb-3">
                                <b-alert show variant="secondary" v-for="(itm,key) in formData.messages" :key="key">
                                    <b-row>
                                        <b-col cols="12" md="1" class="d-flex justify-content-center">
                                            <b-button variant="primary" @click="updateMessage(itm)"><i class="ri-mail-unread-line"></i></b-button>
                                        </b-col>
                                        <b-col cols="12" md="8">
                                            {{itm.message}}
                                        </b-col>
                                        <b-col cols="12" md="2" class="text-center">
                                            <b-badge variant="dark">{{itm.date}}</b-badge>
                                        </b-col>
                                        <b-col cols="12" md="1" class="d-flex justify-content-center">
                                            <b-button pill variant="link" class="text-danger" @click="deleteMessage(itm.id)"><i class="ri-close-line"></i></b-button>
                                        </b-col>
                                    </b-row>
                                </b-alert>
                                <hr>
                                <b-alert show variant="secondary">
                                    <b-row>
                                        <b-col cols="9" md="10">
                                            <b-row>
                                                <b-col cols="12" :md="msgUpdateMode ? 9 : 12">
                                                    <ValidationProvider name="message" rules="" v-slot="{errors}">
                                                        <b-form-group>
                                                            <b-form-textarea v-model="message"
                                                                             :class="errors[0] ? 'is-invalid':''"
                                                                             rows="3"
                                                                             :placeholder="$t('message')"
                                                            />
                                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col cols="12" md="3" v-if="msgUpdateMode">
                                                    <b-form-input type="date" v-model="msgDate" />
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                        <b-col cols="3" md="2">
                                            <b-button block variant="primary" @click="sendMessage" v-if="!msgUpdateMode">
                                                {{$t('send')}}
                                            </b-button>
                                            <b-button block variant="warning" @click="_updateMessage" v-if="msgUpdateMode">
                                                {{$t('update')}}
                                            </b-button>
                                            <b-button block variant="primary" @click="newMessage" v-if="msgUpdateMode">
                                                {{$t('new')}}
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-alert>
                            </div>
                        </template>
                    </b-tab>
                </b-tabs>
                <div class="d-flex justify-content-center mb-3">
                    <processing-button :processing="formLoading" variant="primary" v-if="updateMode"
                                       :label="$t('save')" @click="updateForm" />
                </div>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
    // Component
    import NationalitySelectbox from '@/components/interactive-fields/NationalitySelectbox'
    import CitySelectbox from '@/components/interactive-fields/CitySelectbox'
    import DistrictOutlineSelectbox from "@/components/interactive-fields/DistrictOutlineSelectbox"
    import ProcessingButton from '@/components/elements/ProcessingButton'
    import CampusSelectboxNoCache from "@/components/interactive-fields/CampusSelectboxNoCache"

    // Service
    import FashionDesignService from "@/services/FashionDesignService";
    import ConstantService from "@/services/ConstantService";

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import Branchs from "@/modules/fashionDesignApplication/data/Branchs"
    import moment from "moment";
    import qs from "qs";

    export default {
        props: {
            updateData: {
                type: Object
            },
            updateMode: {
                type: Boolean,
                default: false
            },
            goTab: {
                type: Number,
                default: 0
            }
        },

        components: {
            ValidationObserver,
            ValidationProvider,
            NationalitySelectbox,
            CitySelectbox,
            DistrictOutlineSelectbox,
            ProcessingButton,
            CampusSelectboxNoCache
        },

        data() {
            return {
                formData: {},
                tabIndex: 0,
                estimateFields: [
                    {
                        key: 'message',
                        label: this.$t('message'),
                    },
                    {
                        key: 'date',
                        label: this.$t('date'),
                    },
                ],

                schoolMajorTypeOptions: [
                    { value: null, text: '' },
                    { value: this.$t('high_school_major_type_1'), text: this.$t('high_school_major_type_1') },
                    { value: this.$t('high_school_major_type_2'), text: this.$t('high_school_major_type_2') },
                    { value: this.$t('high_school_major_type_3'), text: this.$t('high_school_major_type_3') },
                    { value: this.$t('high_school_major_type_4'), text: this.$t('high_school_major_type_4') },
                ],

                branchOptions: null,
                formLoading: false,
                programOptions: [],

                message: null,
                msgUpdateMode: false,
                msgDate: null,
                msgId: null
            }
        },

        methods: {
            async updateForm(){
                this.formLoading = true
                const isValid = await this.$refs.updateFormValidate.validate();
                if (isValid) {
                    this.formData.mobile_tel = this.formData.mobile_tel.replace(/[^\d]/g, '')
                    this.formData.alternative_mobile_tel = this.formData.alternative_mobile_tel ? this.formData.alternative_mobile_tel.replace(/[^\d]/g, '') : null
                    if(this.formData.program_code == '11022101'){
                        this.formData.program_detail = this.formData.program_detail.toString()
                    } else {
                        this.formData.program_detail = null
                    }
                    if(this.formData.exam_date) {
                        this.formData.exam_date = moment(this.formData.exam_date).format('YYYY-MM-DD')+' '+this.formData.exam_time;
                    }
                    delete this.formData.exam_time

                    FashionDesignService.update(this.formData,this.formData.id).then(response => {
                        this.$toast.success(this.$t('api.'+response.data.message))
                        this.formLoading = false
                        this.$emit('updated',true)
                    }).catch(e => {
                        this.showErrors(e, this.$refs.updateFormValidate)
                    })
                } else {
                    this.formLoading = false
                }
            },

            loadData(data) {
                this.formData = data
                this.formData.city = parseInt(this.formData.city)
                this.formData.contact_city = parseInt(this.formData.contact_city)
                this.tabIndex = this.goTab
                if(this.formData.exam_date != null) {
                    let sp = this.formData.exam_date.split(' ')
                    this.formData.exam_date = sp[0]
                    this.formData.exam_time = sp[1].substr(0,5)
                }
            },

            downloadFile() {
                if(this.checkPermission('fashiondesignapplication_downloadfile')) {
                    FashionDesignService.downloadFile(this.formData.id).then(response => {
                        this._downloadFile(response, response.headers.file_name)
                    }).catch(e => {
                        this.showErrors(e, null, true)
                    })
                } else {
                    this.$toast.error(this.$t('unauthorized_operation'))
                }
            },

            getProgramOptions(){
                const config = {
                    params: {
                        'key': 'fashion_design_application.program_code'
                    },
                    paramsSerializer: (params) => qs.stringify(params, { encode: false })
                }
                ConstantService.getValue(config).then(response => {
                    let parsed = JSON.parse(response.data.data)
                    this.programOptions = parsed
                    this.formData.program_code = parsed[0].value
                }).catch(e => {
                    this.showErrors(e, null)
                })
            },

            sendMessage(){
                if(this.message){
                    let formData = {
                        fashion_design_application_id: this.formData.id,
                        message: this.message
                    }
                    FashionDesignService.sendMessage(formData).then(response => {
                        this.$toast.success(this.$t('api.'+response.data.message))
                        this.formData.messages.push(response.data.data)
                        this.newMessage()
                    }).catch(e => {
                        this.showErrors(e)
                    })
                } else {
                    this.$toast.error(this.$t('message_is_required'))
                }
            },

            updateMessage(data){
                this.msgUpdateMode = true
                this.message = data.message
                this.msgDate = data.date
                this.msgId = data.id
            },

            newMessage(){
                this.msgUpdateMode = false
                this.message = null
                this.msgDate = null
                this.msgId = null
            },

            _updateMessage(){
                if(this.message && this.msgDate){
                    let formData = {
                        message: this.message,
                        date: this.msgDate
                    }
                    FashionDesignService.updateMessage(formData, this.msgId).then(response => {
                        this.$emit('updatedMsg', true)
                        this.formData.messages.forEach((itm, index) => {
                            if(itm.id == this.msgId) {
                                this.formData.messages.splice(index, 1)
                            }
                        })
                        this.formData.messages.push(response.data.data)
                        this.newMessage()
                        this.$toast.success(this.$t('api.'+response.data.message))
                    }).catch(e => {
                        this.showErrors(e)
                    })
                } else {
                    this.$toast.error(this.$t('message_date_is_required'))
                }
            },

            deleteMessage(messageId){
                this.$swal({
                    title: this.$t('attention'),
                    text: this.$t('message_delete_confirm'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                }).then(response => {
                    if (response.isConfirmed == true) {
                        FashionDesignService.deleteMessage(messageId).then(response => {
                            this.$toast.success(this.$t('api.'+response.data.message))
                            this.$emit('updatedMsg', true)
                            this.newMessage()
                            this.formData.messages.forEach((itm, index) => {
                                if(itm.id == messageId) {
                                    this.formData.messages.splice(index, 1)
                                }
                            })
                        }).catch(e => {
                            this.showErrors(e)
                        })
                    }
                })
            }
        },

        watch: {
            updateData: {
                handler(value) {
                    this.loadData(value)
                }
            },

            goTab: {
                handler(value) {
                    this.tabIndex = value
                }
            }
        },

        created() {
            // Branch Options
            this.branchOptions = Branchs()
            this.loadData(this.updateData)
            this.getProgramOptions()
        },
    }
</script>
