<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('fashion_design_applications')"
                        :isColumns="true"
                        @filter-div-status="datatable.filterStatus=$event"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('fashion_design_applications')"
                              :isColumns="true"
                              @filter-div-status="datatable.filterStatus=$event"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter
                v-show="datatable.filterStatus"
                :otherButton="{ show: true, title: $t('message_bulk_btn'), emit:'bulkMessage'}"
                @bulkMessage="bulkMessage"
                @filter="filter"
                @filterClear="filterClear">
                <b-row>
                    <b-col cols="12" md="8">
                        <b-row>
                            <b-col md="6">
                                <b-form-group :label="$t('name')">
                                    <b-form-input type="text" v-model="datatable.queryParams.filter.name"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group :label="$t('surname')">
                                    <b-form-input type="text" v-model="datatable.queryParams.filter.surname"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group :label="$t('status')">
                                    <multi-selectbox v-model="datatable.queryParams.filter.status"
                                                     :options="statusOptions"
                                                     :multiple="false"/>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group :label="$t('exam_date_enter')">
                                    <true-false-selectbox v-model="datatable.queryParams.filter.is_publish_exam_date"
                                                          :multiple="false"/>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group :label="$t('have_a_message')">
                                    <multi-selectbox v-model="datatable.queryParams.filter.have_message"
                                                     :options="haveMessageOptions"
                                                     :multiple="false"/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="12" md="4">
                        <b-form-group :label="$t('national_id')">
                            <lined-textarea
                                v-model="datatable.queryParams.filter.national_id"
                                :nowrap="false"
                                :disabled="false"
                                :styles="{ height: '13.2em', resize: 'both' }"
                            >
                            </lined-textarea>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            >
            </datatable>
            <CommonModal ref="updateModal" size="xl">
                <template v-slot:CommonModalTitle>
                    <div>{{ updateTitle }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <update-form
                        :update-data="updateData"
                        :update-mode="updateMode"
                        @updated="afterUpdate"
                        @updatedMsg="afterUpdateMsg"
                        :goTab="goTabIndex"
                        v-if="updateTitle"></update-form>
                </template>
            </CommonModal>
            <CommonModal ref="bulkMessageModal" size="xl">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('message_bulk_title') }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <ValidationObserver ref="bulkMessageFormValidate">
                            <b-alert show variant="secondary">
                                <b-row>
                                    <b-col cols="12" class="mb-2">
                                        <b-badge>{{ $t('message_bulk_to') }}</b-badge>
                                    </b-col>
                                    <b-col cols="9" md="10">
                                        <ValidationProvider name="message" rules="required" v-slot="{errors}">
                                            <b-form-group>
                                                <b-form-textarea v-model="bulkMsgForm.message"
                                                                 :class="errors[0] ? 'is-invalid':''"
                                                                 rows="3"
                                                                 :placeholder="$t('message')"
                                                />
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col cols="3" md="2">
                                        <b-button block variant="primary" @click="bulkMessageSend">
                                            {{$t('send')}}
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-alert>
                        </ValidationObserver>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
    // Template
    import AppLayout from '@/layouts/AppLayout'
    import Header from '@/layouts/AppLayout/Header'
    import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

    // Components
    import DatatableFilter from '@/components/datatable/DatatableFilter'
    import Datatable from '@/components/datatable/Datatable'
    import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox"
    import MultiSelectbox from '@/components/interactive-fields/MultiSelectbox'
    import CommonModal from '@/components/elements/CommonModal'
    import TrueFalseSelectbox from '@/components/interactive-fields/TrueFalseSelectbox'
    import LinedTextarea from "@/components/elements/LinedTextarea"

    // Pages
    import UpdateForm from './UpdateForm'

    // Services
    import FashionDesignService from "@/services/FashionDesignService";

    // Others
    import qs from 'qs'
    import Branchs from "@/modules/fashionDesignApplication/data/Branchs"

    export default {
        components: {
            AppLayout,
            Header,
            HeaderMobile,

            ProgramSelectbox,
            MultiSelectbox,
            CommonModal,
            TrueFalseSelectbox,
            LinedTextarea,

            UpdateForm,
            DatatableFilter,
            Datatable,
        },
        metaInfo() {
            return {
                title: this.$t('fashion_design_applications')
            }
        },
        data() {
            return {
                datatable: {
                    isLoading: false,
                    columns: [
                        {
                            label: '',
                            field: 'buttons',
                            html: true,
                            sortable: false,
                            tdClass: 'p-0 text-center w-40 align-middle',
                            buttons: [
                                {
                                    text: this.$t('view'),
                                    class: 'ri-search-eye-line align-middle top-minus-1 mr-3 text-muted',
                                    callback: (row) => {
                                        this.openUpdateModal(row, false,0)
                                    },
                                    permission:"fashiondesignapplication_index"
                                },
                                {
                                    text: this.$t('edit'),
                                    class: 'ri-edit-box-line align-middle top-minus-1 mr-3 text-muted',
                                    callback: (row) => {
                                        this.openUpdateModal(row, true, 0)
                                    },
                                    permission:"fashiondesignapplication_update"
                                },
                                {
                                    text: this.$t('exam_info_enter'),
                                    class: 'ri-calendar-schedule-line align-middle top-minus-1 mr-3 text-muted',
                                    callback: (row) => {
                                        this.openUpdateModal(row, true, 3)
                                    },
                                    permission:"fashiondesignapplication_update"
                                },
                                {
                                    text: this.$t('delete'),
                                    class: 'ri-delete-bin-7-line align-middle top-minus-1 mr-3 text-muted',
                                    show: () => {
                                        return false
                                    },
                                    callback: (row) => {
                                        alert('Sil')
                                    },
                                    //permission:"constant_delete"
                                }
                            ]
                        },
                        {
                            label: this.$t('id'),
                            field: 'id',
                            hidden: false
                        },
                        {
                            label: this.$t('name'),
                            field: 'name',
                            hidden: false
                        },
                        {
                            label: this.$t('surname'),
                            field: 'surname',
                            hidden: false,
                        },
                        {
                            label: this.$t('status'),
                            field: 'status',
                            hidden: false,
                            formatFn: (row) => {
                                let res = row
                                let clss = 'badge-warning'
                                if(row == 'waiting_program'){
                                    res = this.$t('waiting_program')
                                } else if(row == 'waiting'){
                                    res = this.$t('waiting')
                                }
                                return '<span class="badge '+clss+'">'+res+'</span>'
                            }
                        },
                        {
                            label: this.$t('result').toUpper(),
                            field: 'result',
                            hidden: false,
                            formatFn: (row) => {
                                let res = row
                                let clss = 'badge-warning'
                                if(row == 'waiting'){
                                    res = this.$t('waiting')
                                }
                                return '<span class="badge '+clss+'">'+res+'</span>'
                            }
                        }
                    ],
                    rows: [],
                    total: 0,
                    filterStatus: true,
                    showTable: false,
                    queryParams: {
                        filter: {},
                        sort: 'id',
                        page: 1,
                        limit: 20
                    }
                },
                branchs: null,
                updateData: {},
                updateTitle: null,
                updateMode: false,
                goTabIndex: 0,

                statusOptions: [
                    {
                        value: 'waiting_program',
                        text: this.$t('waiting_program')
                    },
                    {
                        value: 'waiting',
                        text: this.$t('waiting')
                    },
                    {
                        value: 'approved',
                        text: this.$t('status_approved')
                    },
                    {
                        value: 'declined',
                        text: this.$t('status_declined')
                    }
                ],

                haveMessageOptions: [
                    {
                        value: 1,
                        text: this.$t('there_is')
                    },
                    {
                        value: '0',
                        text: this.$t('absent')
                    }
                ],

                bulkMsgForm: {}
            }
        },
        created() {
            this.filterSet();
            this.branchs = Branchs()
        },
        methods: {
            filterSet() {
                this.datatable.queryParams.filter = {
                    national_id: null,
                }
            },
            filterClear() {
                this.filterSet()
                this.getRows();
            },
            filter() {
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            onPageChange(params) {
                this.datatable.queryParams.page = params.currentPage;
                this.getRows();
            },
            onPerPageChange(params) {
                this.datatable.queryParams.limit = params.currentPerPage;
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            changeColumn(key) {
                this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
            },
            onSortChange(params) {
                const sortType = params[0].type == 'desc' ? '-' : '';
                this.datatable.queryParams.sort = sortType + params[0].field
                this.getRows();
            },

            getRows() {
                this.datatable.showTable = true;
                this.datatable.isLoading = true;

                if(this.datatable.queryParams.filter.national_id){
                    this.datatable.queryParams.filter.national_id = this.datatable.queryParams.filter.national_id.split(`\n`)
                }

                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                return FashionDesignService.getAll(config).then(response => {
                    this.datatable.rows = response.data.data
                    this.datatable.total = response.data.pagination.total
                }).catch(e => {
                    this.showErrors(e)
                }).finally(() => {
                    this.datatable.isLoading = false;
                });
            },

            openUpdateModal(data, updateMode, goTab){
                this.updateMode = updateMode
                this.updateTitle = data.name+' '+data.surname
                this.updateData = data
                this.goTabIndex = goTab
                this.$refs.updateModal.$refs.commonModal.show()
            },

            afterUpdate(){
                this.$refs.updateModal.$refs.commonModal.hide()
                this.getRows()
            },

            afterUpdateMsg(){
                this.getRows()
            },

            bulkMessage(){
                this.$refs.bulkMessageModal.$refs.commonModal.show()
            },

            async bulkMessageSend(){
                const isValid = await this.$refs.bulkMessageFormValidate.validate();
                if (isValid) {
                    let formData = {
                        filter: this.datatable.queryParams.filter,
                        ...this.bulkMsgForm
                    }
                    FashionDesignService.bulkMessage(formData).then(response => {
                        this.$toast.success(this.$t('api.'+response.data.message))
                        this.$refs.bulkMessageModal.$refs.commonModal.hide()
                        this.bulkMsgForm = {}
                    }).catch(e => {
                        this.showErrors(e)
                    })
                }
            }

        }
    }
</script>

