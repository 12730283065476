import i18n from '@/plugins/i18n';

export default function () {
    let options = [
        {
            value: "classic_music",
            text: i18n.t('classic_music')
        },
        {
            value: "jazz",
            text: i18n.t('jazz')
        },
        {
            value: "turkish_music",
            text: i18n.t('turkish_music')
        },
        {
            value: "jazz_vocal",
            text: i18n.t('jazz_vocal')
        },
        {
            value: "turkish_music_voice_education",
            text: i18n.t('turkish_music_voice_education')
        },
        {
            value: "popular_music_singer",
            text: i18n.t('popular_music_singer')
        },

    ]

    return options
}


